import { createContext, useState } from 'react';
import { ZipCodeSelectionModal } from './ZipCodeSelectionModal';

interface ShowOptions {
  redirectUrl?: string;
  redirectOnSubmit?: boolean;
}

interface ZipCodeSelectionModalContextProps {
  isZipCodeModalShowing: boolean;
  hideZipCodeModal: () => void;
  showZipCodeModal: (opts: ShowOptions) => void;
}

export const ZipCodeSelectionModalContext = createContext<ZipCodeSelectionModalContextProps>({
  isZipCodeModalShowing: false,
  hideZipCodeModal: () => {},
  showZipCodeModal: () => {},
});

export const ZipCodeSelectionModalProvider: React.FC = ({ children }) => {
  const [isZipCodeModalShowing, setisZipCodeModalShowing] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState();
  const [redirectOnSubmit, setRedirectOnSubmit] = useState(false);

  const hideZipCodeModal = () => setisZipCodeModalShowing(false);

  const showZipCodeModal = (opts) => {
    setRedirectUrl(opts.redirectUrl);
    setRedirectOnSubmit(opts.redirectOnSubmit);
    setisZipCodeModalShowing(true);
  };

  return (
    <ZipCodeSelectionModalContext.Provider value={{ isZipCodeModalShowing, hideZipCodeModal, showZipCodeModal }}>
      {children}
      <ZipCodeSelectionModal
        isShowing={isZipCodeModalShowing}
        hide={hideZipCodeModal}
        redirectUrl={redirectUrl}
        redirectOnSubmit={redirectOnSubmit}
      />
    </ZipCodeSelectionModalContext.Provider>
  );
};
