import { ClientEvents } from 'common/events/ClientEvents';
import { getPromoCodeDescriptionForEvent } from 'common/PromoCodeUtilities';
import { StorageKeys } from 'common/storage/constants';
import { currentUrlDate } from 'common/UrlDate';
import { useCallback } from 'react';
import { usePromotionalsHook } from '../../../../promotionals/usePromotionalsHook';
import { useCurrentUser } from '../../../../shared/hooks/useCurrentUserHook';
import { useLocation } from '../../../../shared/hooks/useLocation';
import { useTracker } from '../../../../shared/hooks/useTracker';
import { ConsumerLoginRoutes, ConsumerSubscriptionRoutes, Routes } from '../../../../shared/Routes';
import { useLocalStorage } from '../../../../shared/storage/Storage';
import { locationInRoutes } from '../../../../shared/utils/RouteUtilities';
import { useMealPlanDiscount } from '../../meal-plans/useMealPlanDiscount';

export const useShowConsumerHeaderPersistentBanner = () => {
  const tracker = useTracker();
  const location = useLocation();
  const [, loading] = useCurrentUser();
  const { discount } = useMealPlanDiscount();

  const isOnMealPlanIntake = locationInRoutes(location, [
    Routes.CONSUMER_MEAL_PLANS_PREFERENCES,
    Routes.CONSUMER_MEAL_PLANS_CHECKOUT,
    Routes.CONSUMER_MEAL_PLANS,
  ]);

  const promoCodeContext = usePromotionalsHook();
  const promoCode = promoCodeContext.code;
  const code = promoCode?.code;
  const currentDate = currentUrlDate();
  const referralPromoUsesLeft = promoCode?.usesRemainingForUser ?? 0;
  const isMealPlanFirstOrderEligible = isOnMealPlanIntake && referralPromoUsesLeft < 2 && discount > 0;

  const codeName = isMealPlanFirstOrderEligible ? 'meal-plan-first-order' : code;
  const promoStorageKey = `${StorageKeys.CONSUMER_HEADER_PERSISTENT_PROMO_BANNER_PREFIX}-${codeName}-${currentDate}`;

  const [hasDismissedPromoDialog, setHasDismissedPromoDialog] = useLocalStorage(promoStorageKey, false);

  const handlePromoClose = useCallback(() => {
    setHasDismissedPromoDialog(true);

    if (!promoCode && !isMealPlanFirstOrderEligible) {
      console.error(`Missing promoCode on handleClose, should be impossible`);
    } else if (promoCode) {
      tracker.track(ClientEvents.CONSUMER_HEADER_PERSISTENT_PROMO_BANNER_DISMISSED, {
        promoCode: promoCode.code,
        promoCodeIsPercentage: promoCode.isPercentage,
        value: promoCode.value,
        promoCodeDescription: getPromoCodeDescriptionForEvent(promoCode),
      });
    }
  }, [setHasDismissedPromoDialog, tracker, promoCode]);

  const showBanner =
    (!hasDismissedPromoDialog &&
      !!promoCode &&
      !promoCode?.disabled &&
      !loading &&
      !locationInRoutes(location, [
        Routes.CONSUMER_BECOME_A_SHEF,
        Routes.CONSUMER_BECOME_A_SHEF_REGISTER,
        Routes.CONSUMER_REFER,
        ...ConsumerLoginRoutes,
        ...ConsumerSubscriptionRoutes,
      ])) ||
    (isMealPlanFirstOrderEligible && !hasDismissedPromoDialog);

  return {
    enabled: showBanner,
    handlePromoClose,
    promoCode,
  };
};
