import { isNil } from 'lodash';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { FoodItem } from 'src/gqlReactTypings.generated.d';
import { ExpandableTextSimple } from 'src/shared/design-system/ExpandableText';
import { dietaryTagToFoodItemFilter, DIETARY_TAG_DISPLAY_ORDER } from 'src/shared/utils/diet-utils';
import { DisplayedFoodItemTags } from 'src/shared/utils/IconUtils';
import { FoodName } from '../stylesV2';
import { AllergyWarning } from './AllergyWarning';
import { DetailsTabs } from './DetailsTabs';
import { ModalV2FoodTags } from './ModalV2FoodTags';

interface IModalV2InfoContentProps {
  foodItem: Pick<FoodItem, 'description' | 'ingredients' | 'name'> & DisplayedFoodItemTags;
  cuisines?: string;
  averageRating?: number;
  ratingCount?: number;
  shefMenuUrl?: string;
  publicName?: string;
}

enum DishDetailsTab {
  DESCRIPTION = 'Description',
  MAIN_INGREDIENTS = 'Main ingredients',
}

export const ModalV2InfoContent: React.FC<IModalV2InfoContentProps> = ({
  foodItem,
  cuisines,
  averageRating,
  ratingCount,
  shefMenuUrl,
  publicName,
}) => {
  const dietaryTags = useMemo(
    () => DIETARY_TAG_DISPLAY_ORDER.filter((dietaryTag) => dietaryTagToFoodItemFilter[dietaryTag](foodItem)),
    [foodItem]
  );
  const detailsTabs = {
    [DishDetailsTab.DESCRIPTION]: <ExpandableTextSimple>{foodItem.description ?? ''}</ExpandableTextSimple>,
    [DishDetailsTab.MAIN_INGREDIENTS]: (
      <>
        <span>{foodItem.ingredients}</span>
        <AllergyWarning />
      </>
    ),
  };

  return (
    <>
      <FoodName.Container>
        {cuisines && <FoodName.Cuisines>{cuisines}</FoodName.Cuisines>}
        <FoodName.Name>{foodItem.name}</FoodName.Name>
        <ModalV2FoodTags dietaryTags={dietaryTags} averageRating={averageRating} ratingCount={ratingCount} />
        {!isNil(shefMenuUrl) && !isNil(publicName) && (
          <FoodName.ByShef>
            by <Link to={shefMenuUrl}>{publicName}</Link>
          </FoodName.ByShef>
        )}
      </FoodName.Container>
      <DetailsTabs tabs={detailsTabs} className='my-6' />
    </>
  );
};
