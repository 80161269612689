import { TimeZone } from 'common/Constants';
import { startCase, toLower } from 'lodash';
import { DateTime } from 'luxon';
import { User } from 'src/gqlReactTypings.generated.d';

/**
 * @private
 * Internal enum values of weekdays. Do not display these in UI, use `getWeekdayDisplayName` to get display name of weekday.
 */
export enum Weekday {
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday',
  SUNDAY = 'sunday',
}

/**
 * Enum numeric values of weekdays.
 */
export enum WeekdayNumber {
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
  SUNDAY = 7,
}

/**
 * Get the Weekday enum value from a given WeekdayNumber enum value.
 * @param weekdayNumber The WeekdayNumber enum value.
 * @returns The matching Weekday enum value.
 */
export const getWeekdayFromWeekdayNumber = (weekdayNumber: WeekdayNumber): Weekday => {
  const [key] = Object.entries(WeekdayNumber).find(([, value]) => value === weekdayNumber) || [''];
  return Weekday[key];
};

/**
 * Get the WeekdayNumber enum value from a given Weekday enum value.
 * @param weekday The Weekday enum value.
 * @returns The matching WeekdayNumber enum value.
 */
export const getWeekdayNumberFromWeekday = (weekday: Weekday): WeekdayNumber => {
  const [key] = Object.entries(Weekday).find(([, value]) => value === weekday) || [''];
  return WeekdayNumber[key];
};

/**
 * Get the display name of the given Weekday.
 * @param weekday The weekday to get display name of. Can be either Weekday or WeekdayNumber enum values.
 * @param isShortName Returns the short display name of the weekday.
 * @returns The display name of the weekday.
 */
export const getWeekdayDisplayName = (weekday: Weekday | WeekdayNumber, isShortName?: boolean): string => {
  const weekdayNumber = typeof weekday === 'string' ? getWeekdayNumberFromWeekday(weekday) : weekday;

  // Luxon has Sunday = 0 and shef Sunday = 7.
  const date = DateTime.utc().set({ weekday: weekdayNumber % 7 });
  return isShortName ? date.weekdayShort : date.weekdayLong;
};

export const formatDate = (
  date: Date | string | undefined | null,
  time = true,
  seconds = false,
  forceAmericanEnglishLocale = false,
  timeZone?: string
): string => {
  if (!date) {
    return '';
  }
  if (typeof date === 'string') {
    date = new Date(date);
  }
  const locale = forceAmericanEnglishLocale ? 'en-US' : undefined;
  // toLocaleString(undefined) uses the user's locale to format the date.
  const stringVersion = time
    ? date.toLocaleString(locale, { timeZone })
    : date.toLocaleDateString(locale, { timeZone });
  return seconds ? stringVersion : stringVersion.replace(':00 ', ' ');
};

export const parseDate = (date: any, tz?: TimeZone | string | null) =>
  DateTime.fromJSDate(new Date(date), {
    zone: tz || TimeZone.LosAngeles, // Only possible if the address refers to a zipcode we no longer serve. TODO: Keep zipcodes around but mark as not delivered to.
  });

/**
 * Example
 *   date: October 9, 2021, 10:31am
 *   return: 'Oct 6 at 10am'
 */
export const readableDateAndTime = (date: DateTime) => date.toFormat("MMM d 'at' ha");

export const readableTime = (date: DateTime) => date.toFormat('h:mm a');

export const longDateFormat = `EEEE MMMM d`;
export const shortDateFormat = `EEE MMM d`;

/** Get the full name of the local timezone. */
export const getLocalTimezone = (): string => Intl.DateTimeFormat().resolvedOptions().timeZone;

/** Get the abbreviation of the local timezone. */
export const getLocalTimezoneAbbr = (): string => {
  const timeZone = getLocalTimezone();
  return DateTime.local().setZone(timeZone).offsetNameShort;
};

/**
 * Convert a luxon DateTime, even with timezone defined,
 * to a JS Date in local time.
 * NOTE:  Local time in JS Date doesn't exist. All is in UTC.
 *        This utility converts DateTime to a local form of UTC
 *        that works as local time.
 */
export function dateTimeToLocalDate(dateTime: DateTime): Date {
  return dateTime.setZone('local', { keepLocalTime: true }).toJSDate();
}

/**
 * Converts ISO date string to a month/day format with leading zeroes removed
 * e.g. "1999-01-01" -> "1/1"
 */
export const isoDateStringToMonthDayDateString = (date: string): string => {
  const [, month, day] = date.split('-');
  return `${parseInt(month, 10)}/${parseInt(day, 10)}`;
};

/**
 * Changes the timezone of a date and keeps the time values the same.
 * NOTE: Use mainly for displaying dates in a different timezone. Use
 *       caution if using to save this value in the db since we are
 *       essentially modifing the actual date value.
 * @param dateTime
 * @param timeZone
 */
export const dateTimeUpdateTimezoneKeepValues = (dateTime: DateTime, timeZone: string) =>
  dateTime.setZone(timeZone).set(dateTime.toObject());

export function formatBoolean(bool?: boolean | null) {
  return bool ? <span style={{ color: 'green' }}>Yes</span> : <span style={{ color: 'red' }}>No</span>;
}

export const formatUser = (user?: Pick<User, 'fullName' | 'publicName' | 'id'>, publicUser?: boolean) =>
  user ? `${publicUser ? user.publicName : user.fullName} (${user.id})` : '';

export const titleCase = (val?: string) => startCase(toLower(val));
