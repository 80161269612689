import { ifProp } from 'src/shared/styled-utils/ifProp';
import styled, { css } from 'styled-components';
import { Colors, Font, Spacing } from 'web-common/src/shared/styles';

export const LABEL_COLOR = Colors.GRAY_DARK_10;

export const Label = styled.label<{ showLabel: boolean; focused: boolean; hasError: boolean }>`
  font-family: ${Font.Family.SANS};
  font-size: ${Font.Size.S};
  font-weight: ${Font.Weight.MEDIUM};
  background: none;
  border: 1px solid;
  border-radius: ${Spacing.HALF};
  text-align: left;

  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  align-items: ${ifProp('showLabel', 'flex-start', 'center')};
  height: ${Spacing.TRIPLE_HALF};
  margin-bottom: 0px;

  ${ifProp(
    'hasError',
    css`
      background-color: ${Colors.RADISH_LITE_20};
      border-color: ${Colors.RADISH};
    `,
    css`
      border-color: ${ifProp('focused', Colors.KOHLRABI, LABEL_COLOR)};
    `
  )}
`;

export const LabelSpan = styled.span`
  font-family: ${Font.Family.SANS};
  font-size: ${Font.Size.XXS};
  font-weight: ${Font.Weight.SEMI_BOLD};
  color: ${LABEL_COLOR};

  margin: ${Spacing.HALF} ${Spacing.SINGLE};
  position: absolute;
`;

export const Input = styled.input<{ showLabel: boolean; isPasswordInput?: boolean }>`
  font-family: ${Font.Family.SANS};
  font-size: ${Font.Size.S};
  font-weight: ${Font.Weight.MEDIUM};

  background: none;
  border: none;
  border-radius: inherit;
  color: ${Colors.GRAY_DARK_30};

  box-sizing: border-box;
  display: inline-block;
  padding: ${({ showLabel, isPasswordInput }) =>
    showLabel
      ? `${Spacing.SINGLE_QUARTER} ${isPasswordInput ? Spacing.NONE : Spacing.SINGLE} ${Spacing.NONE} ${Spacing.SINGLE}`
      : `${Spacing.NONE} ${Spacing.SINGLE}`};
  height: 54px;
  width: 100%;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: ${LABEL_COLOR};
  }
`;
