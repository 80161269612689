import React from 'react';
import { SVG } from 'web-common/src/shared/design-system/Utilities';

export const Shef = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <SVG width='60' height='24' viewBox='0 0 60 24' {...props}>
      <title>Shef logo</title>
      <mask
        id='mask0_2838_65457'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='60'
        height='24'>
        <path fillRule='evenodd' clipRule='evenodd' d='M0 0H59.8639V24H0V0Z' fill='white' />
      </mask>
      <g mask='url(#mask0_2838_65457)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M43.5379 0.685547C43.5379 2.20029 42.3195 3.4284 40.8168 3.4284C39.3139 3.4284 38.0957 2.20029 38.0957 0.685547H43.5379Z'
          fill='currentColor'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M7.256 13.8977C9.74973 14.4524 12.9888 15.4952 12.9242 18.9822C12.9242 20.5465 12.3095 21.7844 11.1107 22.6642C9.91197 23.5439 8.42174 24.0003 6.60838 24.0003C3.40115 24.0003 1.03651 22.5672 0 20.1869L3.01212 18.4274C3.56339 19.9918 4.76214 20.7748 6.60838 20.7748C8.45476 20.7748 9.36083 20.1551 9.36083 18.949C9.36083 17.9062 8.00105 17.2864 6.28404 16.8632C3.85486 16.2436 0.582938 15.267 0.647484 11.8438C0.647484 10.3456 1.19876 9.13957 2.33162 8.22674C3.46583 7.3139 4.89137 6.85742 6.5755 6.85742C9.26448 6.85742 11.4339 8.12858 12.5681 10.2475L9.6205 11.9101C9.06923 10.6708 8.03271 10.0525 6.5755 10.0525C5.24752 10.0525 4.21087 10.639 4.21087 11.7787C4.21087 12.8549 5.57187 13.4095 7.256 13.8977Z'
          fill='currentColor'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M29.9318 13.7745V23.9998H26.3804V14.1406C26.3804 11.6429 24.9649 10.2775 22.6965 10.2775C20.1964 10.2775 18.5185 11.8437 18.5185 15.0736V23.9998H14.9658V0.685547H18.5185V9.47878C19.6045 7.77988 21.281 6.91339 23.6164 6.91339C27.3995 6.91339 29.9318 9.51134 29.9318 13.7745Z'
          fill='currentColor'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M45.3802 14.0608C44.8854 11.4205 43.0031 10.0525 40.6583 10.0525C38.0163 10.0525 36.1664 11.5837 35.6715 14.0608H45.3802ZM35.7036 16.9282C36.2322 19.4053 38.2475 20.7748 40.9876 20.7748C42.8701 20.7748 44.2912 20.0901 45.2485 18.7539L48.1876 20.4483C46.5687 22.8273 44.1581 24.0003 40.9554 24.0003C38.2798 24.0003 36.1004 23.1856 34.4494 21.5895C32.7983 19.96 31.9727 17.9062 31.9727 15.4289C31.9727 12.9849 32.7983 10.964 34.4157 9.33466C36.0346 7.6721 38.1481 6.85742 40.6906 6.85742C43.1012 6.85742 45.0831 7.70531 46.6346 9.36774C48.1876 11.0291 48.9795 13.0829 48.9795 15.462C48.9795 15.8203 48.9473 16.3099 48.8477 16.9282H35.7036Z'
          fill='currentColor'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M59.8642 4.13998C57.1941 3.91128 55.8763 4.98983 55.8763 7.37439V7.70147H59.8642V11.098H55.8763V24.034H52.2284V11.098H49.6602V7.70147H52.2284V7.37439C52.2284 2.89919 54.8639 0.449529 59.8642 0.743455V4.13998Z'
          fill='currentColor'
        />
      </g>
    </SVG>
  );
};
