import React from 'react';
import { Circle, Path, SVG } from 'web-common/src/shared/design-system/Utilities';

export const Location: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <SVG width={24} height={24} viewBox='0 0 24 24' {...props}>
    <Path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.598 22.701C15.931 20.597 20 16.061 20 10a8 8 0 1 0-16 0c0 6.061 4.068 10.597 6.402 12.701a2.358 2.358 0 0 0 3.196 0ZM12 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z'
    />
  </SVG>
);

export const LocationOutline: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <SVG width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
    <Circle cx='12' cy='10' r='3' />
    <Path
      d='M19 10C19 15.6195 15.2091 19.9024 12.928 21.9582C12.3856 22.447 11.6144 22.447 11.072 21.9582C8.79087 19.9024 5 15.6195 5 10C5 6.13401 8.13401 3 12 3C15.866 3 19 6.13401 19 10Z'
      stroke='currentColor'
      strokeWidth='2'
      fill='none'
    />
  </SVG>
);

export const MapPin: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <SVG width='16' height='17' viewBox='0 0 16 17' fill='none' {...props}>
    <Path
      d='M10.5 6.66669C10.5 8.07294 9.375 9.16669 8 9.16669C6.59375 9.16669 5.5 8.07294 5.5 6.66669C5.5 5.29169 6.59375 4.16669 8 4.16669C9.375 4.16669 10.5 5.29169 10.5 6.66669ZM8 8.16669C8.8125 8.16669 9.5 7.51044 9.5 6.66669C9.5 5.85419 8.8125 5.16669 8 5.16669C7.15625 5.16669 6.5 5.85419 6.5 6.66669C6.5 7.51044 7.15625 8.16669 8 8.16669ZM14 6.66669C14 9.41669 10.3438 14.2604 8.71875 16.2917C8.34375 16.7604 7.625 16.7604 7.25 16.2917C5.625 14.2604 2 9.41669 2 6.66669C2 3.35419 4.65625 0.666687 8 0.666687C11.3125 0.666687 14 3.35419 14 6.66669ZM8 1.66669C5.21875 1.66669 3 3.91669 3 6.66669C3 7.16669 3.15625 7.82294 3.5 8.66669C3.84375 9.47919 4.3125 10.3542 4.875 11.2292C5.9375 12.9479 7.1875 14.6042 8 15.6042C8.78125 14.6042 10.0312 12.9479 11.0938 11.2292C11.6562 10.3542 12.125 9.47919 12.4688 8.66669C12.8125 7.82294 13 7.16669 13 6.66669C13 3.91669 10.75 1.66669 8 1.66669Z'
      fill='#9E1C4C'
      strokeWidth='2'
    />
  </SVG>
);
