import { oneLine } from 'common-tags';
import { FoodType } from '../gqlReactTypings.generated.d';

export enum TextAlign {
  CENTER = 'center',
  LEFT = 'left',
  RIGHT = 'right',
}

export enum ObjectPosition {
  CENTER = 'center',
  TOP = 'top',
  LEFT = 'left',
  BOTTOM = 'bottom',
  RIGHT = 'right',
}

export enum IndexPosition {
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export const FAVORITE_SHEF_COUNT_TO_SHOW = 3;
export const EMAIL_ANYWHERE_REGEX =
  /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;

export const FOOD_ITEM_IMAGE_WIDTH_TO_HEIGHT_ASPECT_RATIO = 1.8;
export const FOOD_ITEM_CAROUSEL_HEIGHT = 185;
export const FOOD_ITEM_CAROUSEL_HEIGHT_MOBILE = 16 * 10;
export const CUISINE_ICON_WIDTH = 80;
export const SHEF_IMAGE_DIMENSION = 72;

export const IS_BOT_KEY_NAME = 'isBot';

// NOTE IF YOU CHANGE THIS: Make sure to keep this in sync with shef-api/src/Constants.ts
export const MAIN_FOOD_TYPES: (FoodType | undefined | null)[] = [FoodType.Main];
export const SIDE_FOOD_TYPES: (FoodType | undefined | null)[] = [
  FoodType.Appetizer,
  FoodType.Dessert,
  FoodType.Side,
  FoodType.Beverage,
];
export const KID_FOOD_TYPES: (FoodType | undefined | null)[] = [FoodType.Kids];

export const SIDE_FOOD_TYPES_NON_DESSERT: (FoodType | undefined | null)[] = [
  FoodType.Appetizer,
  FoodType.Side,
  FoodType.Beverage,
];
export const DESSERT_FOOD_TYPES: (FoodType | undefined | null)[] = [FoodType.Dessert];

export const COUNTRIES_REPRESENTED = 85;

export const DELIVERY_DATE_TOOLTIP = oneLine`Our shefs source fresh ingredients and cook your meal in traditional, 
no-shortcut ways, which means it’s not available immediately. All dishes come refrigerated 
and stored in an insulated bag, ready to heat and eat!`;

export const TITLE = 'Shef | Homemade food, delivered to your door.';
export const HUBLESS_FAQ_NASH = 'https://help.shef.com/en/articles/8009608-for-shefs-using-door-to-door-delivery';
export const FRINGE_FAQ =
  'https://help.shef.com/en/articles/8009601-shefs-using-dropoff-hubs-and-door-to-door-delivery';
export const DINNER_CUTOFF_FOR_TIME_WINDOWS = 66600000; // 6:30pm  - used for fringe to clarify time windows

export const MEAL_PLAN_REGION_IDS: readonly number[] = [1, 5, 6];
