import { CheckmarkV2, ChevronDownV2 } from 'src/shared/design-system/Icon';
import { Tiered } from 'src/shared/design-system/ProgressBar';
import { ExpandingContainer, TextReplace } from 'src/shared/design-system/Utilities';
import { VerticallyCenteredText } from 'src/shared/design-system/VerticallyCenteredText';
import styled, { css } from 'styled-components';
import { Border, Colors, Font, Mixins, Spacing, Transition } from 'web-common/src/shared/styles';

const MIN_SAVINGS_WIDTH = 180;

export const Container = styled.div`
  ${Mixins.Flex.centerColumn()};
`;

export const Wrapper = styled.div`
  gap: ${Spacing.HALF};
  flex: 1 1 auto;
  flex-wrap: nowrap;
  gap: ${Spacing.DOUBLE};
  width: 100%;
  ${Mixins.Flex.center()}
  ${Mixins.Media.maxWidthSmall(css`
    flex-wrap: wrap;
    gap: ${Spacing.SINGLE};
  `)}
`;

export const ProgressBar = styled(Tiered.EquallyDistributed).attrs(
  ({ isOnDarkBackground = false }: { isOnDarkBackground?: boolean }) => ({
    backgroundColor: isOnDarkBackground ? Colors.WHITE : Colors.GRAY_LITE_10,
    barColor: Colors.ELDERBERRY_DARK_10,
    showGlow: true,
  })
)<{ isOnDarkBackground?: boolean }>`
  flex: 1 1 100%;
  order: 2;
  max-width: 600px;
  ${Mixins.Media.maxWidthSmall(css`
    flex: 1 1 100%;
    order: 1;
    max-width: 100%;
  `)}
`;

export const TotalSavingsContainer = styled.div`
  flex: 1 1 50%;
  min-height: ${Spacing.SINGLE_HALF};
  min-width: ${MIN_SAVINGS_WIDTH}px;
  white-space: nowrap;
  margin: -2px -${Spacing.HALF} -2px 0;
  ${Mixins.Flex.set('flex-end')}
  ${Mixins.Media.maxWidthSmall(css`
    flex: 1 1 auto;
    margin: 0;
    min-width: 0px;
    justify-content: flex-start;
    order: 2;
    white-space: normal;
  `)}
`;

export const TotalSavingsWrapper = styled.div`
  background: ${Colors.GRAY_LITE_20};
  padding: ${Spacing.QUARTER} ${Spacing.HALF};
  border-radius: ${Border.Radius.M};
  display: inline-flex;
  ${Font.bySize(Font.Size.XS)}
`;

export const TotalSavings = styled(TextReplace).attrs(() => ({
  elementType: VerticallyCenteredText,
}))``;

export const RewardsContainer = styled.div`
  display: flex;
  flex: 1 1 50%;
  min-height: ${Spacing.SINGLE_HALF};
  min-width: ${MIN_SAVINGS_WIDTH}px;
  order: 3;
  ${Mixins.Media.maxWidthSmall(css`
    min-width: 0px;
  `)}
`;

export const RewardsWrapper = styled.div`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${Spacing.HALF};
  white-space: nowrap;
  ${Font.bySize(Font.Size.XS)}
  ${Mixins.Media.maxWidthSmall(css`
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end;
    white-space: normal;
  `)}
`;

export const RewardsText = styled.span<{ isBolded?: boolean }>`
  white-space: nowrap;
  ${({ isBolded = false }) => css`
    color: ${isBolded ? Colors.KOHLRABI_DARK_10 : 'inherit'};
    font-weight: ${isBolded ? Font.Weight.BOLD : 'inherit'};
  `}
`;

export const TierIcon = styled(CheckmarkV2)`
  color: ${Colors.WHITE};
  ${Mixins.Shape.square(Spacing.HALF)}
`;

export const DropdownIcon = styled(ChevronDownV2)<{ isOpen?: boolean }>`
  transform-origin: center;
  transition: transform ${Transition.Time.BASE};
  ${Mixins.Shape.square(Spacing.SINGLE)}
  ${({ isOpen = false }) => css`
    transform: rotateZ(${isOpen ? '180deg' : '0deg'});
  `}
  ${Mixins.Media.maxWidthSmall(css`
    margin-bottom: ${Spacing.QUARTER};
  `)}
`;

export const BreakdownContainer = styled(ExpandingContainer)`
  width: 100%;
  ${Mixins.Flex.center()}

  & > div {
    padding-top: ${Spacing.SINGLE_HALF};
  }
`;
