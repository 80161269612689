import { checkEmail, checkPassword } from '@shef/validators';
import { checkFirstAndLastName } from '@shef/validators/dist/src/checkName';
import { ZIP_CODE_REGEX } from 'common/Constants';
import { ShefErrorCode } from 'common/errors/ShefErrors';
import { ClientEvents } from 'common/events/ClientEvents';
import { isLeft, isRight } from 'fp-ts/lib/Either';
import { isNil } from 'lodash';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { isShefApiResponseErrorWithCode } from 'src/error/isShefApiResponseError';
import { useRegisterMutation } from 'src/gqlReactTypings.generated.d';
import { CheckboxInput } from 'src/shared/design-system/Input/CheckboxInput/CheckboxInput';
import { FormErrorMessage } from 'src/shared/design-system/Input/Error/FormErrorMessage';
import { DualLabeledTextInput } from 'src/shared/design-system/Input/LabeledTextInput/DualLabeledTextInput';
import { LabeledTextInput } from 'src/shared/design-system/Input/LabeledTextInput/LabeledTextInput';
import { useTextInputCallback } from 'src/shared/hooks/useInputCallback';
import { Routes } from 'src/shared/Routes';
import { UserPreferences } from 'src/user-preferences/userPreferencesTypes';
import styled from 'styled-components';
import { Colors, Font, Spacing } from 'web-common/src/shared/styles';
import { usePromotionalsHook } from '../../promotionals/usePromotionalsHook';
import { useTracker } from '../../shared/hooks/useTracker';
import { useUserPreferences } from '../../user-preferences/useUserPreferences';
import { useHandleLogin } from '../useHandleLogin';
import { AuthActionButton } from './AuthActionButton';

interface SignUpWithEmailProps {
  email: string;
  onEmailChange: (event: React.FormEvent<HTMLInputElement>) => void;
  onSuccess?: (isExistingUser: boolean) => void;
  onUserAlreadyExists: (email: string, errorMessage: string) => void;
  requireZipCode?: boolean;
  signUpPromoCode?: string;
  signUpEntryPoint?: string | null;
}

// Negative margin, since we're using gap for spacing
const ErrorMessageWithMargin = styled(FormErrorMessage)`
  margin-top: -${Spacing.HALF};
`;

const TOSText = styled.span`
  > a {
    color: ${Colors.GRAY_DARK_30} !important;
    font-weight: ${Font.Weight.BOLD};
    text-decoration: underline;
  }
`;

const GENERIC_SIGN_UP_MESSAGE = 'There was an error signing up. Please try again.';

export const SignUpWithEmail: React.FC<SignUpWithEmailProps> = ({
  email,
  onEmailChange,
  onSuccess,
  onUserAlreadyExists,
  requireZipCode,
  signUpPromoCode,
  signUpEntryPoint,
}) => {
  const [emailErrorMessage, setEmailErrorMessage] = useState<string | undefined>();
  const [nameErrorMessage, setNameErrorMessage] = useState<string | undefined>();
  const [zipCodeErrorMessage, setZipCodeErrorMessage] = useState<string | undefined>();
  const [passwordErrorMessage, setPasswordErrorMessage] = useState<string | undefined>();
  const [signUpErrorMessage, setSignUpErrorMessage] = useState<string | undefined>();
  const [tosChecked, setTosChecked] = useState(false);
  const [firstName, onFirstNameChange] = useTextInputCallback('');
  const [lastName, onLastNameChange] = useTextInputCallback('');
  const [zipCode, onZipCodeChange] = useTextInputCallback('');
  const [password, onPasswordChange] = useTextInputCallback('');

  const [{ utm, [UserPreferences.ZIPCODE]: cachedZipCode }] = useUserPreferences();
  const { code } = usePromotionalsHook();
  const promoCode = code?.code;

  const tracker = useTracker();
  const handleLogin = useHandleLogin();

  const [registerMutation, { loading }] = useRegisterMutation();

  const register = () =>
    registerMutation({
      variables: {
        userInput: { email, password, firstName, lastName },
        utm,
        zipCode: zipCode || cachedZipCode,
        promoCode: signUpPromoCode ?? promoCode,
        signUpEntryPoint: signUpEntryPoint ?? null,
      },
    })
      .then(({ data, errors }) => {
        if (isNil(data) || !isNil(errors)) {
          const invalidEmailError = errors?.find((error) =>
            isShefApiResponseErrorWithCode(error, ShefErrorCode.INVALID_EMAIL)
          );
          const accountAlreadyExistsError = errors?.find((error) =>
            isShefApiResponseErrorWithCode(error, ShefErrorCode.SIGN_UP_USER_ALREADY_EXISTS)
          );
          if (!isNil(accountAlreadyExistsError)) {
            onUserAlreadyExists(email, 'An account with this email already exists. Please log in.');
          } else if (!isNil(invalidEmailError)) {
            setEmailErrorMessage(invalidEmailError.message);
          } else {
            setSignUpErrorMessage(GENERIC_SIGN_UP_MESSAGE);
          }
        } else {
          handleLogin('email', data.register);

          if (onSuccess) {
            onSuccess(data.register.isExistingUser);
          }
        }
      })
      .catch((error) => {
        setSignUpErrorMessage(GENERIC_SIGN_UP_MESSAGE);
        tracker.track(ClientEvents.SIGN_UP_ERROR, {
          method: 'email',
          errors: [error.message],
        });
      });

  const validateForm = () => {
    setEmailErrorMessage(undefined);
    setNameErrorMessage(undefined);
    setZipCodeErrorMessage(undefined);
    setPasswordErrorMessage(undefined);
    setSignUpErrorMessage(undefined);

    const emailCheck = checkEmail(email);
    if (isLeft(emailCheck)) {
      setEmailErrorMessage(emailCheck.left);
    }

    const nameCheck = checkFirstAndLastName({ firstName, lastName });
    if (isLeft(nameCheck)) {
      setNameErrorMessage(nameCheck.left);
    }

    const isZipCodeValid = !requireZipCode || ZIP_CODE_REGEX.test(zipCode);
    if (!isZipCodeValid) {
      setZipCodeErrorMessage('Please enter a valid zip code');
    }

    const passwordCheck = checkPassword(password);
    if (isLeft(passwordCheck)) {
      setPasswordErrorMessage(passwordCheck.left);
    }

    if (!tosChecked) {
      setSignUpErrorMessage('You must accept our Terms of Service to register');
    }

    return isRight(emailCheck) && isRight(nameCheck) && isZipCodeValid && isRight(passwordCheck) && tosChecked;
  };

  const handleSubmitForm = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    if (loading) {
      return;
    }

    const isFormValidated = validateForm();
    if (isFormValidated) {
      register();
    }
  };

  // Theoretically this shouldn't be needed – not sure why enter isn't handling form submission like normal
  const handleKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmitForm();
    }
  };

  return (
    <form onSubmit={handleSubmitForm} onKeyDown={handleKeyDown} data-cy='sign-up-form'>
      <fieldset className='flex flex-col gap-4' disabled={loading}>
        <LabeledTextInput
          label='Email'
          name='email'
          value={email}
          onChange={onEmailChange}
          errorMessage={emailErrorMessage}
          data-cy='sign-up-email'
        />
        <DualLabeledTextInput
          topLabel='First name'
          topName='firstname'
          topValue={firstName}
          onTopChange={onFirstNameChange}
          bottomLabel='Last name'
          bottomName='lastname'
          bottomValue={lastName}
          onBottomChange={onLastNameChange}
          errorMessage={nameErrorMessage}
          autoFocusTop
          top-data-cy='sign-up-first-name'
          bottom-data-cy='sign-up-last-name'
        />
        {requireZipCode && (
          <LabeledTextInput
            label='Zip Code'
            name='zipCode'
            value={zipCode}
            onChange={onZipCodeChange}
            errorMessage={zipCodeErrorMessage}
            data-cy='sign-up-zip-code'
          />
        )}
        <LabeledTextInput
          password
          label='Password'
          name='password'
          value={password}
          onChange={onPasswordChange}
          errorMessage={passwordErrorMessage}
          data-cy='sign-up-password'
        />
        <CheckboxInput
          checked={tosChecked}
          onChange={() => setTosChecked((checked) => !checked)}
          data-cy='sign-up-tos'
          gap={Spacing.THREE_QUARTERS}>
          <TOSText>
            {'By selecting "Sign up" you agree to Shef\'s '}
            <Link to={Routes.CONSUMER_TOS} target='_blank' className='primary'>
              Terms of Service
            </Link>
            {', '}
            <Link to={Routes.CONSUMER_PRIVACY_POLICY} target='_blank' className='primary'>
              Privacy Policy
            </Link>
            , and to receive text messages
          </TOSText>
        </CheckboxInput>
        {signUpErrorMessage ? <ErrorMessageWithMargin>{signUpErrorMessage}</ErrorMessageWithMargin> : null}
        <AuthActionButton value='Sign up' disabled={loading} data-cy='sign-up-button' />
      </fieldset>
    </form>
  );
};
