import { GraphQLError } from 'graphql';
import { createGlobalState } from 'src/create-global-state/createGlobalState';
import { CurrentUserFieldsFragment, Maybe, RegionInfo } from 'src/gqlReactTypings.generated.d';
import { initialPromoCodeState } from 'src/promotionals/promotionalsState';
import { initialHelpButtonState } from 'src/shared/design-system/HelpButton/helpButtonState';
import { Routes } from 'src/shared/Routes';
import { initialUserPreferencesState } from 'src/user-preferences/userPreferencesState';

export interface IConfigs {
  appleSignInOnWebEnabled: boolean;
  apiSyncEnabled: boolean;
  chatEnabled: boolean;
  chatSupportKeywords: string[];
  chatFeedbackKeywords: string[];
  chatCustomizationKeywords: string[];
  chatBusinessRiskKeywords: string[];
  dishFirstNav: boolean;
  dishwasher: boolean;
  enableAdminPages: boolean;
  foodItemAudits: boolean;
  giftCardPurchaseEnabled: boolean;
  googleSignInOAuth2Enabled: boolean;
  holidayMenusEnabled: boolean;
  profileDeliveryAddressesEnabled: boolean;
  profilePaymentMethodsEnabled: boolean;
  rateRecentOrderPopupEnabled: boolean;
  requestAppReviewsEnabled: boolean;
  shefDashboardInsights: boolean;
  shefMenuChecklistEnabled: boolean;
  shefPlatformUsageQuestionnaire: boolean;
  shefPostsEnabled: boolean;
  shefRatingEnabled: boolean;
  shefApplicationAlwaysEnableContinueInformationSessionVideo: boolean;
  consumerDeleteUserEnabled: boolean;
  shefGrowYourBusinessEnabled: boolean;
  personalShefEnabled: boolean | null;
  shefSideCustomizations: boolean;
  fringeOverrideEnabled: boolean;
  bulkDiscountEnabled: boolean;
  upfrontDeliveryFeeEnabled: boolean;
  mealPlansEnabled: boolean;
  mealPlansAllergensEnabled: boolean;
  mealPlansBrowseAsGuest: boolean;
  showManageMealPlans: boolean;
}

/**
 * The hook which stitches together our various modules for global state. Every module should
 * export its own initial state.
 *
 * The returned `useScopedReducer` hook is the primary way to make new hooks which use global
 * state:
 *
 *    export const useCounter() {
 *      const { state, reduce } = useScopedReducer('counter')
 *      const counter = state.counter;
 *      const increment = reduce(counter => counter + 1);
 *      const decrement = reduce(counter => counter - 1);
 *      return [counter, increment, decrement];
 *    }
 *
 * Note that the API returned by your hook is not restricted to anything in particular.
 */

interface IGlobalFetchState {
  loading: boolean;
  loaded: boolean;
  userRegionId: Maybe<number>;
  userRegionName: Maybe<string>;
  region: Maybe<Pick<RegionInfo, 'id'>>;
  timeZone: Maybe<string>;
  minimumAmountForDiscountedShipping: Maybe<number>;
  discountedDeliveryFee: Maybe<number>;
  deliveryFee: Maybe<number>;
  errors: readonly GraphQLError[] | string[] | undefined;
  currentUser: Maybe<CurrentUserFieldsFragment>;
  isImpersonating: Maybe<boolean>;
  shefReferral: Maybe<string>;
  configs: IConfigs;
}

const initialGlobalFetchState: IGlobalFetchState = {
  loading: false,
  loaded: false,
  errors: undefined,
  currentUser: null,
  isImpersonating: false,
  userRegionId: null,
  userRegionName: null,
  region: null,
  timeZone: null,
  minimumAmountForDiscountedShipping: null,
  discountedDeliveryFee: null,
  deliveryFee: null,
  shefReferral: null,
  configs: {
    appleSignInOnWebEnabled: false,
    apiSyncEnabled: false,
    chatEnabled: false,
    chatSupportKeywords: [] as string[],
    chatFeedbackKeywords: [] as string[],
    chatCustomizationKeywords: [] as string[],
    chatBusinessRiskKeywords: [] as string[],
    dishFirstNav: false,
    dishwasher: false,
    enableAdminPages: false,
    foodItemAudits: false,
    giftCardPurchaseEnabled: false,
    googleSignInOAuth2Enabled: false,
    holidayMenusEnabled: false,
    profileDeliveryAddressesEnabled: false,
    profilePaymentMethodsEnabled: false,
    rateRecentOrderPopupEnabled: false,
    requestAppReviewsEnabled: false,
    shefDashboardInsights: false,
    shefMenuChecklistEnabled: false,
    shefPlatformUsageQuestionnaire: false,
    shefPostsEnabled: false,
    shefRatingEnabled: false,
    shefApplicationAlwaysEnableContinueInformationSessionVideo: true,
    consumerDeleteUserEnabled: false,
    shefGrowYourBusinessEnabled: false,
    personalShefEnabled: false,
    shefSideCustomizations: false,
    fringeOverrideEnabled: false,
    bulkDiscountEnabled: false,
    upfrontDeliveryFeeEnabled: false,
    mealPlansEnabled: false,
    mealPlansBrowseAsGuest: false,
    mealPlansAllergensEnabled: false,
    showManageMealPlans: false,
  },
};

const initialBackButtonState = {
  backButtonVisible: false,
  backPath: Routes.CONSUMER_LANDING,
};

export const initialState = {
  ...initialUserPreferencesState,
  ...initialPromoCodeState,
  ...initialGlobalFetchState,
  ...initialHelpButtonState,
  ...initialBackButtonState,
};

export const { useGlobalReducer, useScopedReducer, GlobalStateProvider } = createGlobalState(initialState);
