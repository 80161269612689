import { animated, useSpring } from 'react-spring';
import { MinusV2, PlusV2 } from 'src/shared/design-system/Icon';
import { VerticallyCenteredText } from 'src/shared/design-system/VerticallyCenteredText';
import styled, { css } from 'styled-components';
import { match } from 'ts-pattern';
import { ifProp } from 'web-common/src/shared/styled-utils/ifProp';
import { Border, Colors, Font, Mixins, Spacing, Transition } from 'web-common/src/shared/styles';

const ACTIVE_COLOR = Colors.RADISH;
const NEUTRAL_COLOR = Colors.GRAY_DARK_10;

export const Container = styled.div<{
  compact?: boolean;
  minimize?: boolean;
  highlight?: boolean;
  showBorderMinimized?: boolean;
}>`
  background: ${Colors.WHITE};
  border-radius: ${Border.Radius.M};
  border: ${Border.Base}
    ${({ minimize, showBorderMinimized }) =>
      match({ minimize, showBorderMinimized })
        .with({ showBorderMinimized: true, minimize: true }, () => ACTIVE_COLOR)
        .with({ minimize: false }, () => NEUTRAL_COLOR)
        .with({ showBorderMinimized: false }, () => 'transparent')
        .otherwise(() => Spacing.HALF)};
  gap: ${({ compact, minimize }) =>
    match({ compact, minimize })
      .with({ minimize: true }, () => 0)
      .with({ compact: true }, () => 0)
      .otherwise(() => Spacing.HALF)};
  overflow: hidden;
  max-height: calc(${Spacing.DOUBLE_HALF} + ${Spacing.EIGHTH});
  transition: background ${Transition.Time.BASE}, border ${Transition.Time.BASE}, gap ${Transition.Time.BASE};
  ${Mixins.Flex.center()}
`;

export const QuantityContainer = styled.div<{ minimize?: boolean }>`
  color: ${Colors.GRAY_DARK_30};
  opacity: 1;
  min-width: ${Spacing.THREE_QUARTERS};
  text-align: center;
  width: auto;
  transition: color ${Transition.Time.BASE}, padding ${Transition.Time.BASE};

  ${ifProp(
    'minimize',
    css`
      color: ${Colors.WHITE};
      opacity: 0;
      padding: 0;
      min-width: 0px;
      width: 0px;
      pointer-events: none;
    `
  )}
`;

type QuantityButtonType = {
  $minimize?: boolean;
};

export const QuantityButton = styled(animated.button).attrs(({ $minimize }: QuantityButtonType) => {
  const style = useSpring({
    maxWidth: $minimize ? '0px' : Spacing.DOUBLE_HALF,
    opacity: $minimize ? 0 : 1,
    minWidth: $minimize ? '0px' : Spacing.DOUBLE_HALF,
  });
  return { style };
})<QuantityButtonType>`
  background: none;
  border: none;
  display: flex;
  height: ${Spacing.DOUBLE_HALF};
  padding: 0px;
  outline: none !important;

  ${ifProp(
    '$minimize',
    css`
      padding-left: 0;
      padding-right: 0;
      pointer-events: none;
    `
  )}

  ${ifProp(
    'disabled',
    css`
      cursor: default;
    `,
    css`
      cursor: pointer;
      &:active svg {
        transform: scale(1.3);
      }
    `
  )}

  & svg {
    height: ${Spacing.DOUBLE};
    width: ${Spacing.DOUBLE};
    transition: all ${Transition.Time.BASE};
  }
`;

export const QuantityWrapper = styled.div<{ minimize?: boolean }>`
  font-size: ${Font.Size.M};
  position: relative;
`;

export const QuantityNumber = styled(VerticallyCenteredText)<{ hidden?: boolean }>`
  opacity: ${ifProp('hidden', 0, 1)};
`;

type QuantityNumberContainerType = {
  $animate?: boolean;
  $isGreater?: boolean;
  $hide?: boolean;
};

export const QuantityNumberContainer = styled.div<QuantityNumberContainerType>`
  position: absolute;
  inset: 0;
  pointer-events: none;
`;

export const QuantityButtonWrapper = styled.div<{ $highlight?: boolean }>`
  align-items: center;
  background: ${ifProp('$highlight', ACTIVE_COLOR, 'none')};
  justify-content: center;
  flex: 1;
  display: flex;
  height: 100%;
  transition: background ${Transition.Time.BASE};
`;

export const MinimizeQuantityNumber = styled(VerticallyCenteredText)`
  color: ${Colors.WHITE};
  font-size: ${Font.Size.M};
  font-weight: ${Font.Weight.BOLD};
`;

export const AddIcon = styled(PlusV2)<{ disabled?: boolean }>`
  color: ${ifProp('disabled', NEUTRAL_COLOR, ACTIVE_COLOR)};
  padding: ${Spacing.HALF};
`;

export const SubstractIcon = styled(MinusV2)<{ disabled?: boolean }>`
  color: ${ifProp('disabled', NEUTRAL_COLOR, ACTIVE_COLOR)};
  padding: ${Spacing.HALF};
`;
