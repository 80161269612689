import cn from 'classnames';
import MoneyIcon from 'src/assets/svg/meal-plan-banner/meal-plan-promo-banner-icon.svg';
import { useMealPlanDiscount } from 'src/pages/consumer/meal-plans/useMealPlanDiscount';
import { useScreenContext } from 'web-common/src/shared/context/ScreenContext/hook';

interface MealPlanPromoBannerProps {
  limitedTimeOnlyChip?: boolean;
  hideMoneyIconOnSmallScreen?: boolean;
}
export const MealPlanPromoBanner: React.FC<MealPlanPromoBannerProps> = ({
  limitedTimeOnlyChip = false,
  hideMoneyIconOnSmallScreen = true,
}: MealPlanPromoBannerProps) => {
  const { isSmallScreen } = useScreenContext();
  const { isPercentageDiscount, totaledDiscountText, promoText } = useMealPlanDiscount();

  return (
    <div className='flex h-[88px] w-full items-center justify-between gap-3 rounded-lg bg-kale-100 py-2 px-4 shadow-light-drop'>
      <div className='flex flex-col gap-2'>
        {limitedTimeOnlyChip && (
          <div className='mb-1 flex w-fit items-center justify-center rounded bg-jackfruit-300 py-1 px-2 text-xs font-semibold'>
            LIMITED TIME ONLY!
          </div>
        )}
        <div className='text-lg font-bold leading-[120%] text-neutral-800'>
          {isPercentageDiscount ? promoText : `Claim your ${totaledDiscountText} welcome offer!`}
        </div>
        <div className='text-xs font-semibold leading-5 text-neutral-700'>
          {!limitedTimeOnlyChip && <span>Limited time, </span>}
          {limitedTimeOnlyChip ? 'D' : 'd'}iscount applied at checkout
        </div>
      </div>
      <div className={cn('flex items-center justify-center', { hidden: isSmallScreen && hideMoneyIconOnSmallScreen })}>
        <img src={MoneyIcon} alt='meal plan promo banner' className='h-14 w-14' />
      </div>
    </div>
  );
};
