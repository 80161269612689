import { MEAL_PLAN_SHEF_IDS } from 'common/Constants';
import { RouteChildrenProps } from 'react-router';
import { MEAL_PLANS_PREFERENCES_FIRST_STEP } from 'src/pages/consumer/meal-plans/preferences/consts';
import { Routes } from '../Routes';
import { getIdSluggedPath } from '../utils/RouteUtilities';
import { CurrentUser } from './useCurrentUserHook';

export const useMealPlanRedirect = (
  currentUser: CurrentUser | null,
  currentUserLoaded: boolean,
  history: RouteChildrenProps['history']
) => {
  if (currentUserLoaded) {
    const fakeShefUrls = MEAL_PLAN_SHEF_IDS.map((shefId) => [
      getIdSluggedPath(Routes.CONSUMER_ORDER_ONE_TIME, shefId),
    ]).flat();

    const isAFakeShefUrl = fakeShefUrls.some((url) => history.location.pathname.startsWith(url));

    if (isAFakeShefUrl) {
      const redirectPath = currentUser?.hasActiveMealPlan
        ? Routes.CONSUMER_MEAL_PLANS_UPCOMING_ORDER
        : MEAL_PLANS_PREFERENCES_FIRST_STEP;

      history.replace(redirectPath);
    }
  }
};
