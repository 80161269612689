import { isNil } from 'lodash';
import { VerticallyCenteredText } from 'src/shared/design-system/VerticallyCenteredText';
import styled from 'styled-components';
import { Colors, FontSize, FontWeight } from 'web-common/src/shared/styles';

const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;

  width: 88px;

  border-right: 1px solid ${Colors.GRAY_LITE_20};
`;

const HighlightText = styled(VerticallyCenteredText)`
  flex-grow: 0;

  color: ${Colors.KOHLRABI_DARK_10};
  font-style: italic;
  font-weight: ${FontWeight.SEMI_BOLD};
  font-size: ${FontSize.XXS};
`;

interface ICustomizationOptionProps {
  icon: React.ReactNode;
  mainText: string;
  subText?: string;
  highlightText?: string;
}

export const CustomizationOption: React.FC<ICustomizationOptionProps> = ({
  icon,
  mainText,
  subText,
  highlightText,
}) => (
  <div className='flex h-full flex-row items-stretch'>
    <IconContainer>{icon}</IconContainer>
    <div className='ml-4 flex grow flex-row flex-wrap content-center py-2'>
      <div className='flex grow flex-wrap items-baseline gap-x-2'>
        <VerticallyCenteredText className='text-base font-medium text-neutral-800'>{mainText}</VerticallyCenteredText>
        {!isNil(subText) && (
          <VerticallyCenteredText className='text-sm font-medium text-neutral-700'>{subText}</VerticallyCenteredText>
        )}
      </div>
      {!isNil(highlightText) && <HighlightText>{highlightText}</HighlightText>}
    </div>
  </div>
);
