import { noOp } from 'common/Constants';
import { ClientEvents } from 'common/events/ClientEvents';
import { isEmpty, isNil } from 'lodash';
import React, { ChangeEvent, useCallback, useEffect } from 'react';
import { DishModalFoodItemFragment } from 'src/gqlReactTypings.generated.d';
import { MealPlanFoodItem } from 'src/pages/consumer/meal-plans/checkout/types';
import { useTracker } from 'src/shared/hooks/useTracker';
import { CartFoodItem } from 'src/store/cart/types';
import { useScreenContext } from 'web-common/src/shared/context/ScreenContext/hook';
import { getTopKCuisines } from '../../DishFirstCard/utils';
import { Close, Share } from './components';
import { ServingSizeCustomizations } from './components/Customizations/ServingSizeCustomizations';
import { SpiceLevelCustomizations } from './components/Customizations/SpiceLevelCustomizations';
import { ModalReviews } from './components/ModalReviews';
import { ModalV2Footer } from './components/ModalV2Footer';
import { ModalV2InfoContent } from './components/ModalV2InfoContent';
import { DEFAULT_IMAGE, FOOD_IMAGE_PROPS } from './consts';
import { useModalFoodImageParallax } from './hooks/useModalParallax';
import { FoodImage, Modal } from './stylesV2';

interface IBaseDishModalProps {
  foodItem: CartFoodItem | MealPlanFoodItem;
  loadedData?: DishModalFoodItemFragment;
  isShowing?: boolean;
  minQuantity?: number;
  maxQuantity?: number | null;
  showAddButton?: boolean;
  unitsInCart: number;
  shefMenuUrl?: string;
  shareUrl?: string;
  isEditingOrder?: boolean;
  showModalPrompt?: boolean;
  openModal?: () => void;
  onClose?: () => void;
  onAddToCart?: () => void;
  isEditingCart: boolean;
  submit: () => void;
  canSubmitCart: boolean;
  setFormState: React.Dispatch<React.SetStateAction<IBaseDishModalFormState>>;
  formState: IBaseDishModalFormState;
  productType?: 'marketplace' | 'subscriptions';
  showPortionSizeControls?: boolean;
  onQuantityChange?: (quantity: number) => void;
}

export interface IBaseDishModalFormState {
  servingSizeCustomizationId?: string | undefined;
  spiceLevelCustomizationId: string;
  quantity: number;
}

export const BaseDishModal: React.FC<IBaseDishModalProps> = (props) => {
  const {
    foodItem,
    loadedData,
    isShowing = false,
    onClose: handleClose = noOp,
    maxQuantity,
    shefMenuUrl,
    shareUrl,
    minQuantity,
    submit,
    canSubmitCart,
    isEditingCart = false,
    showModalPrompt,
    openModal,
    formState,
    setFormState,
    productType = 'marketplace',
    onQuantityChange,
    showPortionSizeControls = true,
  } = props;

  const { spiceLevelCustomizations, servingSizeCustomizations } = foodItem;

  const { isSmallScreen } = useScreenContext();
  const { wrapperProps, foodImageContainerProps, foodImageProps, headerProps, headerImageProps, wavyEdgeProps } =
    useModalFoodImageParallax();

  const PortionCustomizationsContainer = isNil(servingSizeCustomizations)
    ? Modal.SectionContainer
    : Modal.GraySectionContainer;
  const SpiceLevelCustomizationsContainer = isNil(spiceLevelCustomizations)
    ? Modal.SectionContainer
    : Modal.GraySectionContainer;

  const tracker = useTracker();

  useEffect(() => {
    if (isShowing) {
      tracker.track(ClientEvents.DISH_MODAL_VISIT, {
        foodItemId: foodItem.id,
        type: 'customizations',
        productType,
        dishServingSizeOptionsCount: servingSizeCustomizations?.length ?? 0,
        dishSpiceLevelOptionsCount: spiceLevelCustomizations?.length ?? 0,
      });
    }
    // Intentionally only adding isShowing to dependencies, since this is for resetting the modal
  }, [isShowing]);

  const handleServingSizeChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setFormState({ ...formState, servingSizeCustomizationId: e.currentTarget.value });
    },
    [setFormState, formState]
  );

  const handleSpiceLevelChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setFormState({ ...formState, spiceLevelCustomizationId: e.currentTarget.value });
    },
    [setFormState, formState]
  );

  const handleQuantityChange = useCallback(
    (newQuantity: number) => {
      onQuantityChange?.(newQuantity);
      setFormState({ ...formState, quantity: newQuantity });
    },
    [setFormState, formState]
  );

  const cuisines = !isNil(loadedData) ? getTopKCuisines(loadedData) : undefined;
  return (
    <Modal.Main isShowing={isShowing} onClose={handleClose} isFullHeight={isSmallScreen} isFullWidth={isSmallScreen}>
      <Modal.Wrapper {...wrapperProps}>
        <Modal.Header {...headerProps}>
          <Close onClick={handleClose} />
          <FoodImage.MiniImage
            src={foodItem?.imageUrl ?? DEFAULT_IMAGE}
            alt={foodItem.name ?? FOOD_IMAGE_PROPS.alt}
            {...headerImageProps}
          />
          {shareUrl && <Share to={shareUrl} />}
        </Modal.Header>
        <FoodImage.Container {...foodImageContainerProps}>
          <FoodImage.Image
            src={foodItem?.imageUrl ?? DEFAULT_IMAGE}
            alt={foodItem.name ?? FOOD_IMAGE_PROPS.alt}
            {...foodImageProps}
          />
        </FoodImage.Container>

        <Modal.Body>
          <Modal.TopBodyEdge {...wavyEdgeProps} />
          <ModalV2InfoContent
            foodItem={foodItem}
            shefMenuUrl={shefMenuUrl}
            publicName={loadedData?.shef.publicName}
            cuisines={cuisines}
            averageRating={loadedData?.averageRating}
            ratingCount={loadedData?.ratingCount}
          />
          {showPortionSizeControls && (
            <PortionCustomizationsContainer>
              <ServingSizeCustomizations
                defaultPortion={foodItem.portion}
                defaultServingSize={foodItem.servingSize}
                defaultPrice={foodItem.price}
                servingSizeOptions={servingSizeCustomizations ?? undefined}
                onChange={handleServingSizeChange}
                value={formState.servingSizeCustomizationId}
              />
            </PortionCustomizationsContainer>
          )}
          <SpiceLevelCustomizationsContainer>
            <SpiceLevelCustomizations
              defaultSpiceLevel={foodItem.spiceLevel}
              spiceLevelOptions={spiceLevelCustomizations ?? undefined}
              onChange={handleSpiceLevelChange}
              value={formState.spiceLevelCustomizationId}
            />
          </SpiceLevelCustomizationsContainer>
          <ModalReviews foodItemId={foodItem.id} ratingData={loadedData} />
        </Modal.Body>

        <ModalV2Footer
          isEditingCart={isEditingCart}
          quantity={formState.quantity}
          onQuantityChange={handleQuantityChange}
          minQuantity={minQuantity}
          maxQuantity={maxQuantity ?? undefined}
          hasBeenUpdated={canSubmitCart}
          // bit hacky but good enough for now
          isServingSizeSelected={isEmpty(servingSizeCustomizations) || !isNil(formState.servingSizeCustomizationId)}
          actionButtonClick={submit}
          showModalPrompt={showModalPrompt}
          openModal={openModal}
        />
      </Modal.Wrapper>
    </Modal.Main>
  );
};
