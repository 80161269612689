import React from 'react';
import { ifProp } from 'src/shared/styled-utils/ifProp';
import styled from 'styled-components';
import { Colors, Font, Spacing } from 'web-common/src/shared/styles';

const StyledInput = styled.input<{ disabled?: boolean }>`
  font-family: ${Font.Family.SANS};
  font-weight: ${Font.Weight.SEMI_BOLD};
  font-size: ${Font.Size.S};
  color: ${Colors.WHITE};

  background-color: ${ifProp('disabled', Colors.GRAY, Colors.RADISH)};
  border-radius: ${Spacing.HALF};
  border: none;

  text-align: center;
  padding: ${Spacing.NONE} ${Spacing.SINGLE_QUARTER};
  box-sizing: border-box;
  height: ${Spacing.TRIPLE_HALF};
  width: 100%;
`;

interface AuthActionButtonProps {
  value: string;
}

export const AuthActionButton: React.FC<AuthActionButtonProps & React.ComponentPropsWithoutRef<'input'>> = (props) => (
  <StyledInput {...props} type='submit' />
);
