import { useApolloClient } from '@apollo/client';
import { noop } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { ChatTokenDocument, ChatTokenQuery, ChatTokenQueryVariables } from 'src/gqlReactTypings.generated.d';
import { useFeatureFlag } from 'src/shared/design-system/FeatureFlag';
import { useCurrentUser } from 'src/shared/hooks/useCurrentUserHook';
import { Event as StreamEvent, StreamChat } from 'stream-chat';
import { ChatContextType } from '../ChatContext';

// eslint-disable-next-line prefer-destructuring
const REACT_APP_STREAM_CHAT_API_KEY = process.env.REACT_APP_STREAM_CHAT_API_KEY;

export const useChatClient = (): Pick<ChatContextType, 'client' | 'loading' | 'unread'> => {
  const apolloClient = useApolloClient();
  const [currentUser, currentUserIsLoading] = useCurrentUser();
  const [unread, setUnread] = useState<number>(0);
  const isEnabled = useFeatureFlag('chatEnabled');

  const [client, setClient] = useState<StreamChat | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const tokenProvider = useCallback(async () => {
    const { data } = await apolloClient.mutate<ChatTokenQuery, ChatTokenQueryVariables>({
      mutation: ChatTokenDocument,
    });

    return data?.chatToken ?? '';
  }, [apolloClient]);

  useEffect(() => {
    // Listens for events from Stream.io and update unread count
    const eventHandler = (event?: StreamEvent) => {
      if (!event || event?.total_unread_count === undefined) {
        return;
      }

      // TODO: filter unread by shef/consumer (?)
      setUnread(event.total_unread_count);
    };

    client?.on(eventHandler);
    return () => client?.off(eventHandler);
  }, [client, setUnread]);

  useEffect(() => {
    if (!isEnabled || !REACT_APP_STREAM_CHAT_API_KEY) {
      return noop;
    }

    if (!currentUser) {
      setLoading(currentUserIsLoading);
      return noop;
    }

    setLoading(true);

    (async () => {
      const streamSdk = await import('stream-chat');
      const client = streamSdk.StreamChat.getInstance(REACT_APP_STREAM_CHAT_API_KEY);
      client
        .connectUser(
          {
            id: currentUser.flakeId,
            name: currentUser.publicName,
            image: currentUser.imageUrl,
          },
          tokenProvider
        )
        .then((response) => {
          /* eslint-disable-next-line @typescript-eslint/prefer-optional-chain --
        odd behavior with the response type being void, so we can't really use optional chaining here */
          if (response && response.me?.total_unread_count) {
            setUnread(response.me.total_unread_count);
          }
          setClient(client);
        })
        .catch(console.error)
        .finally(() => setLoading(false));
    })();

    return noop;
  }, [currentUser, currentUserIsLoading, isEnabled, tokenProvider]);

  useEffect(() => {
    return () => {
      client?.disconnectUser();
    };
  }, [client]);

  return { client, loading, unread };
};
