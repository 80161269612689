import cn from 'classnames';
import React, { ButtonHTMLAttributes } from 'react';

export enum ButtonType {
  Primary = 'primary',
  Secondary = 'secondary',
  TextLink = 'textLink',
}

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  buttonType?: ButtonType;
  icon?: React.ReactNode;
  iconPosition?: 'left' | 'right';
  align?: 'center' | 'edges';
}

const Button: React.FC<ButtonProps> = ({
  children,
  buttonType,
  disabled = false,
  icon,
  iconPosition = 'left',
  align,
  ...props
}) => (
  <button
    {...props}
    className={cn(
      'box-border flex h-[56px] max-w-[400px] cursor-pointer items-center justify-center gap-2 rounded-xl text-base outline-none',
      {
        'border-none bg-radish-100 text-white hover:bg-radish-200 focus:border-solid focus:border-radish-300 active:bg-radish-300':
          buttonType === ButtonType.Primary && !disabled,
        'border-2 border-solid border-neutral-800 bg-white hover:border-3 focus:bg-neutral-50 active:border-2 active:border-neutral-300':
          buttonType === ButtonType.Secondary && !disabled,
        'bg-neutral-200 text-white': Boolean(disabled && buttonType !== ButtonType.TextLink),
        'text-nowrap border-none bg-white text-neutral-800 underline hover:text-radish-100 focus:font-bold active:text-radish-100 active:text-radish-200':
          buttonType === ButtonType.TextLink,
        'border-none bg-white text-neutral-200': Boolean(disabled && buttonType === ButtonType.TextLink),
        'flex-row-reverse': iconPosition === 'right',
        'justify-between': align === 'edges',
        'w-full px-6': buttonType !== ButtonType.TextLink,
      }
    )}
    disabled={disabled}>
    {Boolean(icon) && icon}
    {children}
  </button>
);

export default Button;
