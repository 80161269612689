import React from 'react';
import { CheckmarkV2 } from 'src/shared/design-system/Icon';
import { ifProp } from 'src/shared/styled-utils/ifProp';
import styled from 'styled-components';
import { Colors, Font, Spacing } from 'web-common/src/shared/styles';

const StyledInput = styled.input`
  &[type='checkbox'] {
    display: none;
  }
`;

const Checkbox = styled.span<{ checked?: boolean }>`
  border: 1px solid;
  border-radius: ${Spacing.QUARTER};
  border-color: ${ifProp('checked', Colors.RADISH, Colors.GRAY_DARK_20)};

  display: flex;
  align-items: center;
  justify-content: center;

  flex-shrink: 0;
  height: ${Spacing.SINGLE_HALF};
  width: ${Spacing.SINGLE_HALF};
  margin: ${Spacing.NONE} ${Spacing.HALF} ${Spacing.NONE} ${Spacing.NONE};
`;

const StyledLabel = styled.label<{ gap?: string }>`
  font-family: ${Font.Family.SANS};
  font-size: ${Font.Size.XS};
  font-weight: ${Font.Weight.SEMI_BOLD};
  color: ${Colors.GRAY_DARK_20};
  gap: ${(props) => (props.gap ? props.gap : Spacing.NONE)};
  display: flex;
  margin: 0;
`;

const StyledCheckmark = styled(CheckmarkV2)`
  color: ${Colors.RADISH};

  width: ${Spacing.THREE_QUARTERS};
`;

interface ICheckboxInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  'data-cy': string;
  gap?: string;
}

export const CheckboxInput: React.FC<ICheckboxInputProps> = ({
  children,
  name,
  checked,
  'data-cy': dataCy,
  gap,
  ...otherProps
}) => (
  <StyledLabel htmlFor={name} data-cy={dataCy} gap={gap}>
    <StyledInput name={name} {...otherProps} type='checkbox' />
    <Checkbox checked={checked}>{checked ? <StyledCheckmark /> : null}</Checkbox>
    {children}
  </StyledLabel>
);
