import { MEAL_PLAN_REGIONS_IDS, ZIP_CODE_REGEX } from 'common/Constants';
import { ExperimentNames, EXP_VAR_TREATMENT } from 'common/experiments/ExperimentDefinitions';
import { formatGqlErrors } from 'common/GqlUtilities';
import { Slugs } from 'common/urls/Slugs';
import { useCallback, useMemo, useState } from 'react';
import { useVariant } from 'src/experimentation/useVariant';
import { ZipCodeAvailabilityDocument, ZipCodeAvailabilityQuery } from 'src/gqlReactTypings.generated.d';
import { MEAL_PLANS_PREFERENCES_FIRST_STEP } from 'src/pages/consumer/meal-plans/preferences/consts';
import { useHistory } from 'src/shared/hooks/useHistory';
import { Routes } from 'src/shared/Routes';
import { useGqlClient } from 'src/shared/useGqlClient';
import { EXTRA_SLUGS, getSluggedPath, QUERY_PARAMS } from 'src/shared/utils/RouteUtilities';
import { useCachedZipCode } from 'src/user-preferences/useCachedZipCode';
import { useCurrentUser } from '../shared/hooks/useCurrentUserHook';

interface UseZipCodeInputParams {
  onZipSubmit?: (zipCode: string) => void;
  redirectRoute?: string;
  extraSlugs?: EXTRA_SLUGS;
  queryParams?: QUERY_PARAMS;
  redirectOnSubmit?: boolean;
}

export const useZipCodeInput = ({
  redirectRoute = Routes.CONSUMER_EXPLORE,
  extraSlugs = [],
  queryParams = {},
  onZipSubmit,
  redirectOnSubmit = true,
}: UseZipCodeInputParams) => {
  const history = useHistory();
  const [cachedZipCode, setCachedZipCode] = useCachedZipCode();
  const { variant, logExposure } = useVariant(ExperimentNames.MEAL_PLAN_HOMEPAGE_ROUTING_V1, false);
  const userIsInTreatment = variant?.name === EXP_VAR_TREATMENT;
  const GqlClient = useGqlClient();
  const [currentUser] = useCurrentUser();

  const [zipCode, setZipCode] = useState(cachedZipCode?.trim() ?? '');

  const isZipValid = useMemo(() => ZIP_CODE_REGEX.test(zipCode), [zipCode]);

  const onChange = useCallback((newZipCode: string) => {
    setZipCode(newZipCode.trim());
  }, []);

  const onSubmit = useCallback(async () => {
    if (!isZipValid) return;
    onZipSubmit?.(zipCode);

    setCachedZipCode(zipCode);

    if (!redirectOnSubmit) return;

    const { data, errors } = await GqlClient.query<ZipCodeAvailabilityQuery>({
      query: ZipCodeAvailabilityDocument,
      variables: { zipCode },
      fetchPolicy: 'network-only',
    });

    if (errors) {
      console.error(formatGqlErrors(errors));
    }
    const useExperiment =
      redirectRoute === Routes.CONSUMER_EXPLORE &&
      !currentUser &&
      data.zipCode?.region?.id != null &&
      MEAL_PLAN_REGIONS_IDS.includes(data.zipCode?.region?.id);
    const giveUserMealPlanTreatment = userIsInTreatment && useExperiment;

    if (useExperiment) {
      logExposure();
    }

    const firstStepRoute = MEAL_PLANS_PREFERENCES_FIRST_STEP;

    const route = giveUserMealPlanTreatment
      ? firstStepRoute
      : getSluggedPath(redirectRoute, [{ slug: Slugs.ZIP_CODE, value: zipCode }, ...extraSlugs], queryParams);

    setZipCode('');
    if (redirectRoute === firstStepRoute && ![1, 2, 5, 6, 8, 13].includes(data.zipCode?.region?.id ?? 0)) {
      /**
       * Hack for meal-plans test
       * If the users region isn't one of the 6 major regions (including New Jersey)
       * Redirect them to the waitlist page
       */
      history.push(Routes.WAITLIST_HOME);
    } else if (data.zipCode?.region?.isInWaitlist && !currentUser) {
      history.push(getSluggedPath(Routes.CONSUMER_REGISTER, [{ slug: Slugs.REDIRECT, value: route }]));
    } else {
      history.push(route);
    }
  }, [isZipValid, onZipSubmit, zipCode, GqlClient, redirectRoute, extraSlugs, queryParams, currentUser, history]);

  return {
    cachedZipCode,
    zipCode,
    onSubmit,
    onChange,
  };
};
